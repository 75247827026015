import React, { useEffect, useState } from "react";
import notFound from "@/assets/svg/404.svg";
import Image from "next/image";
import Link from "next/link";
import styles from "../../../styles/page_not_found.module.scss";
import { useSelector } from "react-redux";
import { selectAuth } from "@/store/slices/auth";
import httpClient from "@/api/http-client";
import endpoints from "@/api/endpoints";
import { getCookie } from "cookies-next";
import Best_Sellers from "@/components/home/best_sellers";
import Trending_Design from "@/components/home/trending_design";
import Recommended from "@/components/home/recommended";

const PageNotFound = () => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    unbxd();
  }, []);

  const unbxd = async () => {
    const data = await httpClient.get(endpoints.unbxd(), {
      params: { pageType: "HOME", uid: getCookie("unbxd.userId") },
    });
    setData(data);
  };

  return (
    <>
      <div style={{ width: "40%", margin: "60px auto" }}>
        <Image src={notFound} alt="" />
      </div>
      <div className="col-md-12">
        <Link href="/" passHref>
          <a className={styles.back}>Go to Home</a>
        </Link>{" "}
      </div>

      {Object.keys(data).length && (
        <>
          <Best_Sellers product={data} />
          <Trending_Design product={data} pagetype="HOME" />
          <Recommended product={data} />
        </>
      )}
    </>
  );
};

export default PageNotFound;
