import PageNotFound from "@/components/common/page-not-found/page-not-found";
import Layout from "@/layout/layout";
import Head from "next/head";
import React from "react";

const Custom404 = () => {
  return (
    <>
      <Head>
        <title>Page Not Found | Senco Gold & Diamonds</title>
        <meta name="title" content="Page Not Found | Senco Gold & Diamonds" />
      </Head>
      <PageNotFound />
    </>
  );
};

Custom404.getLayout = (page: React.ReactElement) => {
  return <Layout>{page}</Layout>;
};

export default Custom404;
